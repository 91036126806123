import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(main)/profile": [6,[3]],
		"/(main)/sitemap": [~7,[3]],
		"/(main)/turneringar": [~8,[3]],
		"/(main)/turneringar/[slug]": [9,[3]],
		"/(aside)/[go=redirect]/[...operator]": [~4,[2]],
		"/(main)/[...slug]": [~5,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';